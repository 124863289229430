export class Image {
    id: string;
    urlImage: string;
    name: string;

    constructor() {
        this.id = null;
        this.urlImage = null;
        this.name = null;
    }
}

export class ImageResult {
    value: string;
}

