import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { lastValueFrom } from 'rxjs';
import { Country } from 'src/app/models/country.model';

@Injectable({
    providedIn: 'root',
})
export class CountryService {
    private baseUrl = environment.baseUrl;

    constructor(private readonly httpClient: HttpClient) { }

    retrieveCountries(): Promise<Country[]> {
        const url = this.baseUrl + 'Country/getAll';
        return lastValueFrom(this.httpClient.get<Country[]>(url));
    }
}
