import { Injectable } from '@angular/core';
import {
  NavController,
  ToastController,
  LoadingController,
  AlertController,
  ModalController,
} from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { ProfileModelPage } from '../about-me/profile-model/profile-model.page';
import { Router } from '@angular/router';
import {
  Camera,
  CameraResultType,
  CameraSource,
  CameraPhoto,
} from '@capacitor/camera';
import { getUnixTime } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  cartData = [];
  isLoading = false;
  public dataTransfer: any = [];
  singleOrder: any = {};
  discountData: any = {};

  public isUpdateProfile = new BehaviorSubject(true);
  public isupdatelanguage = new BehaviorSubject(true);
  public updatecar = new BehaviorSubject(true);
  public saveaddress = new BehaviorSubject(true);
  constructor(
    public toastController: ToastController,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private router: Router,
    private modalController: ModalController
  ) { }

  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 5000,
    });
    toast.present();
  }

  async alertVersion(isIos: boolean) {
    const alert = await this.alertController.create({
      cssClass: 'alert-adverG',
      header: 'Nueva versión disponible',
      subHeader:
        'Encontramos una nueva versión de Topmai , por favor actualiza para tener la última versión',
      message:
        'Presiona en actualizar para ir al playstore y descargar la última versión',
      buttons: [
        'Cancelar',
        {
          text: 'Actualizar',
          handler: () => {
            window.open(
              isIos
                ? 'https://apps.apple.com/us/app/topmai-marketplace/id1627842897'
                : 'https://play.google.com/store/apps/details?id=com.topmai.app',
              '_system',
              'location=yes'
            );
          },
        },
      ],
    });

    await alert.present();
  }

  async startLoad() {
    this.isLoading = true;
    return await this.loadingController
      .create({
        duration: 10000,
        spinner: 'lines',
        message: 'Please Wait',
        cssClass: 'custom-loader-class',
        mode: 'ios',
      })
      .then((a) => {
        a.present().then(() => {
          if (!this.isLoading) {
            a.dismiss().then(() => { });
          }
        });
      });
  }

  async loading2segundos(mensaje) {
    const actualizando = await this.loadingController.create({
      message: mensaje,
      duration: 1500,
      spinner: 'lines',
      mode: 'ios',
      cssClass: 'custom-loader-class',
    });
    actualizando.present();
  }

  async loading1segundos(mensaje) {
    const loadingunsegundo = await this.loadingController.create({
      message: mensaje,
      duration: 1000,
      spinner: 'lines',
      cssClass: 'custom-loader-class',
    });
    loadingunsegundo.present();
  }

  async closeLoading(): Promise<any> {
    this.isLoading = false;
    return await this.loadingController.dismiss();
  }

  async loading18segundos(mensaje) {
    this.isLoading = true;
    return await this.loadingController
      .create({
        duration: 18000,
        spinner: 'lines',
        message: mensaje,
        mode: 'ios',
        cssClass: 'custom-loader-class',
      })
      .then((a) => {
        a.present().then(() => {
          if (!this.isLoading) {
            a.dismiss().then(() => { });
          }
        });
      });
  }

  async dismissLoader() {
    this.isLoading = false;
    return await this.loadingController.dismiss();
  }

  async presentAlertPerfil() {
    const alert = await this.alertController.create({
      cssClass: 'alert-adverG',
      mode: 'ios',
      message:
        
        'Para habilitar esta acción debes completar tu perfil de usuario',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          },
        },
        {
          text: 'Completar',
          handler: () => {
            console.log('Confirm Okay');
            this.openProfileModal();
          },
        },
      ],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
  }

  async openProfileModal() {
    const modal = await this.modalController.create({
      component: ProfileModelPage,
    });
    modal.onDidDismiss().then((data) => {
      if (data.data.dismissed === 'reconsultarperfil') {
      }
    });
    return await modal.present();
  }

  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  readAsBase64(cameraPhoto: CameraPhoto) {
    const blob = this.b64toBlob(
      cameraPhoto.base64String,
      `image/${cameraPhoto.format}`
    );

    const unixTimestamp = getUnixTime(new Date());

    const file = new File([blob], unixTimestamp + '.' + cameraPhoto.format, {
      lastModified: unixTimestamp,
      type: blob.type,
    });

    return file;
  }

  async presentAlertSucces(error) {
    const alert = await this.alertController.create({
      cssClass: 'alert-adverS',
      mode: 'ios',
      message:  error,
      buttons: ['OK'],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
  }
}
