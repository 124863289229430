import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { lastValueFrom,BehaviorSubject  } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../authentication.service';
import { LocalStorageKeyEnum } from 'src/app/models/enums/local-storage-key-enum';
import { ApiResponse } from 'src/app/models/api-response.model';
import { isNil } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private baseUrl = environment.baseUrl;
  private userProfile: any;
   // BehaviorSubject para manejar la señal de actualización del perfil
   private refreshProfileSubject = new BehaviorSubject<boolean>(false);
   refreshProfile$ = this.refreshProfileSubject.asObservable();
  private get currentUserProfile(): any {
    if (!this.userProfile) {
      const userProfileStored = localStorage.getItem(
        LocalStorageKeyEnum.UserProfile
      );
      if (userProfileStored) {
        this.userProfile = JSON.parse(userProfileStored);
      }
    }
    return this.userProfile;
  }

  private set currentUserProfile(userProfile: any) {
    this.userProfile = userProfile;
    localStorage.setItem(
      LocalStorageKeyEnum.UserProfile,
      JSON.stringify(userProfile)
    );
    localStorage.setItem('wTP', userProfile?.WalletId ?? null);
  }

  constructor(
    private readonly httpClient: HttpClient,
    private readonly authenticationService: AuthenticationService
  ) {}

  async getUserProfile(forceRefresh: boolean = false): Promise<any> {
    if (!forceRefresh && this.currentUserProfile) {
      return this.currentUserProfile;
    }

    const url =
      this.baseUrl +
      `Profile/get?idProfile=${this.authenticationService.userId}`;
    try {
      const profileResult = await lastValueFrom(
        this.httpClient.get<ApiResponse<any>>(url)
      );
      this.currentUserProfile = profileResult.result;
      return this.currentUserProfile;
    } catch (error) {
      console.error('Error while trying to retrieve user profile', error);
      this.getCurrentProfile();
    }
  }

  getCurrentProfile() {
    const userProfileStored = localStorage.getItem(
      LocalStorageKeyEnum.UserProfile
    );
    if (userProfileStored) {
      this.userProfile = JSON.parse(userProfileStored);
    }
    return this.userProfile;
  }

  createprofile(data: any): Promise<any> {
    const url = this.baseUrl + 'User/createProfile';
    return lastValueFrom(this.httpClient.post(url, data));
  }

  clearUserProfile(): void {
    this.currentUserProfile = null;
  }
  
  // Método para activar la señal de actualización del perfil
  triggerRefreshProfile() {
    this.refreshProfileSubject.next(true);
  }

  // Método para resetear la señal de actualización del perfil
  resetRefreshProfileFlag() {
    this.refreshProfileSubject.next(false);
  }
}
