import { Injectable } from '@angular/core';
import { GoogleAuth, User } from '@codetrix-studio/capacitor-google-auth';
import { UtilService } from './util.service';
import {
  UserSocialRequest,
  UserRegisterData,
  UserTokenData,
  UserValidationCode,
  UserData,
} from '../models/user.model';
import { LocalStorageKeyEnum } from '../models/enums/local-storage-key-enum';
import { SocialTypeEnum } from '../models/enums/social-type-enum.model';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../models/api-response.model';
import { isNil } from 'lodash';


import { GlobalEventService } from './global-event.service';
import { ChangeForgottenPassword } from '../models/change-forgotten-password.model';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { SignInWithApple } from '@capacitor-community/apple-sign-in';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private userTokenData: UserTokenData;
  private userData: UserData;
  private baseUrl = environment.baseUrl;
  private confirmationResult: any;

  constructor(
    private readonly utilService: UtilService,
    private readonly httpClient: HttpClient,
    private readonly globalEventService: GlobalEventService,
    private afAuth: AngularFireAuth
  ) {}

  private get userTokenInformation(): UserTokenData {
    if (!this.userTokenData) {
      const userTokenStored = localStorage.getItem(
        LocalStorageKeyEnum.UserTokenInformation
      );
      if (userTokenStored) {
        this.userTokenData = JSON.parse(userTokenStored);
      }
    }
    return this.userTokenData;
  }

  private set userTokenInformation(userToken: UserTokenData) {
    this.userTokenData = userToken;
    localStorage.setItem(
      LocalStorageKeyEnum.UserTokenInformation,
      JSON.stringify(userToken)
    );
    localStorage.setItem('uid', userToken.idUser);
  }

  private get currentUserData(): UserData {
    if (!this.userData) {
      const userDataStored = localStorage.getItem(LocalStorageKeyEnum.UserData);
      if (userDataStored) {
        this.userData = JSON.parse(userDataStored);
      }
    }
    return this.userData;
  }

  private set currentUserData(userData: UserData) {
    this.userData = userData;
    localStorage.setItem(
      LocalStorageKeyEnum.UserData,
      JSON.stringify(userData)
    );
  }

  get userId(): string {
    if (isNil(this.userTokenInformation)) {
      return null;
    }

    return this.userTokenInformation.idUser;
  }

  get userToken(): string {
    if (isNil(this.userTokenInformation)) {
      return null;
    }
    return this.userTokenInformation.token;
  }

  get userPhone(): string {
    if (isNil(this.userData)) {
      return null;
    }
    return this.userData.phone;
  }

  async getCurrentUser(forceRefresh: boolean = false): Promise<UserData> {
    if (!forceRefresh && this.currentUserData) {
      return this.currentUserData;
    }

    const url = this.baseUrl + `User/get?idUser=${this.userId}`;
    try {
      const userResult = await lastValueFrom(
        this.httpClient.get<ApiResponse<UserData>>(url)
      );
      this.currentUserData = userResult.result;
      return this.currentUserData;
    } catch (error) {
      console.error('Error while trying to retrieve user data', error);
    }
  }

  login(userEmail: string, userPassword: string): Promise<boolean> {
    const datalogin = {
      accessData: userEmail,
      password: userPassword,
    };

    const url = this.baseUrl + 'User/login';
    console.log(url);
    return lastValueFrom(
      this.httpClient.post<UserTokenData>(url, datalogin)
    ).then((userToken: UserTokenData) => {
      this.userTokenInformation = userToken;
      console.log(userToken);
      return true;
    });
  }

  async register(userRegisterData: UserRegisterData): Promise<boolean> {
    console.log(this.baseUrl)
    const url = this.baseUrl + 'User/create';
    console.log(url)
    return lastValueFrom(
      this.httpClient.post<ApiResponse<UserTokenData>>(url, userRegisterData)
    ).then((registerReponse: ApiResponse<UserTokenData>) => {
      this.userTokenInformation = registerReponse.result;
      return true;
    });
  }

  generateCodeEmailByUserId(idUser: string): Promise<ApiResponse<boolean>> {
    console.log(idUser);
    const url =
      this.baseUrl +
      'CodeValidation/GenerateCodeEmailByIdUser?idUser=' +
      idUser;
    return lastValueFrom(this.httpClient.get<ApiResponse<boolean>>(url));
  }

  generarCodeEmailByEmail(email: string): Promise<ApiResponse<boolean>> {
    const url =
      this.baseUrl + 'CodeValidation/GenerateCodeEmailByEmail?email=' + email;
    return lastValueFrom(this.httpClient.get<ApiResponse<boolean>>(url));
  }

  generarCodePhone(numeroCelular: string): Promise<ApiResponse<boolean>> {
    const url =
      this.baseUrl +
      'CodeValidation/GenerateCodePhoneByPhone?phone=' +
      numeroCelular;
    return lastValueFrom(this.httpClient.get<ApiResponse<boolean>>(url));
  }

  validateUser(userId: string, userPassword: string): Promise<any> {
    const data = {
      id: userId,
      validated: true,
      role: localStorage.getItem('rol'),
      password: userPassword,
    };

    const url = this.baseUrl + 'User/edit';
    return lastValueFrom(this.httpClient.post(url, data));
  }

  validateEmailCode(
    validationCode: UserValidationCode
  ): Promise<ApiResponse<boolean>> {
    const url = this.baseUrl + 'CodeValidation/ValidateCodeEmail';
    return lastValueFrom(
      this.httpClient.post<ApiResponse<boolean>>(url, validationCode)
    );
  }

  changeForgottenPassword(
    data: ChangeForgottenPassword
  ): Promise<ApiResponse<boolean>> {
    const url = this.baseUrl + 'User/changeForgottenPassword';
    return lastValueFrom(this.httpClient.post<ApiResponse<boolean>>(url, data));
  }

  async googleLogin(): Promise<[UserSocialRequest, string]> {
    let googleUser: User;
    try {
      googleUser = await GoogleAuth.signIn();
    } catch (exception) {
      console.log(exception.error)
      return [null, exception.error] as [UserSocialRequest, string];
    }

    const userApi: UserSocialRequest = {
      name: googleUser.givenName,
      lastName: googleUser.familyName,
      imagen: googleUser.imageUrl,
      id: googleUser.id,
      email: googleUser.email,
    };
    console.log(userApi);

    return this.socialLogin(userApi.email, googleUser.id,userApi.name,userApi.lastName)
      .then(async (userToken: UserTokenData) => {
        this.userTokenInformation = userToken;

        localStorage.setItem(
          LocalStorageKeyEnum.UserSocialInformation,
          JSON.stringify(userApi)
        );

        return [userApi, 'connected'] as [UserSocialRequest, string];
      })
      .catch((loginError) => {
        console.log(loginError);
        return [userApi, loginError.error] as [UserSocialRequest, string];
      });
  }

  async googleSignOut() {
    await GoogleAuth.signOut();
  }
  async appleLogin(): Promise<[UserSocialRequest, string]> {
    let appleResponse: any;
    try {
      appleResponse = await this.signInWithApple();
      console.log(appleResponse);

    } catch (exception) {
      console.log(exception.error);
      return [null, exception.error] as [UserSocialRequest, string];
    }
  
    const userApi: UserSocialRequest = {
      name: appleResponse.response.givenName || '', // Si el nombre no está disponible, usamos una cadena vacía
      lastName: appleResponse.response.familyName || '', // Si el apellido no está disponible, usamos una cadena vacía
      imagen: '', // Apple no proporciona una imagen por defecto
      id: appleResponse.response.user, // El `user` es el Apple ID del usuario
      email: appleResponse.response.user,
    };
    console.log(userApi);
  
    return this.socialLogin(userApi.email, userApi.id, userApi.name, userApi.lastName)
      .then(async (userToken: UserTokenData) => {
        this.userTokenInformation = userToken;
  
        localStorage.setItem(
          LocalStorageKeyEnum.UserSocialInformation,
          JSON.stringify(userApi)
        );
  
        return [userApi, 'connected'] as [UserSocialRequest, string];
      })
      .catch((loginError) => {
        console.log(loginError);
        return [userApi, loginError.error] as [UserSocialRequest, string];
      });
  }
  
  async signInWithApple(): Promise<any> {
    try {
      const response = await SignInWithApple.authorize();
      return response;
    } catch (error) {
      throw error;
    }
  }
  
  

  async socialRegister(
    userApi: UserSocialRequest,
    signupType: SocialTypeEnum
  ): Promise<boolean> {
    try {
      const userTokenData = await this.socialRegisterApi(
        userApi.id,
        userApi.email,
        signupType
      );
      console.log(userTokenData);

      this.userTokenInformation = userTokenData.result;
      localStorage.setItem('logincon', 'mail');
      localStorage.setItem(
        LocalStorageKeyEnum.UserSocialInformation,
        JSON.stringify(userApi)
      );

      return true;
    } catch (error) {
      console.log(error);
      console.error('Error while trying to register', error);
      this.utilService.presentToast(error.error.error);

      return false;
    }
  }

  facebookSignIn() {}

  appleSignIn() {}

  logoutFacebook() {}

  logout(): void {
    this.globalEventService.publishSomeData({
      data: 'logout',
    });
    this.userTokenData = null;
    this.userData = null;
    this.logoutFacebook();
    this.googleSignOut();
    localStorage.clear();
    localStorage.setItem('intro-seen', 'yes');
  }

  public signInWithPhoneNumber(recaptchaVerifier, phoneNumber) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth
        .signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
        .then((confirmationResult) => {
          this.confirmationResult = confirmationResult;
          resolve(confirmationResult);
        })
        .catch((error) => {
          console.log(error);
          reject('SMS not sent');
        });
    });
  }
  public async enterVerificationCode(code) {
    return new Promise<any>((resolve, reject) => {
      this.confirmationResult
        .confirm(code)
        .then(async (result) => {
          console.log(result);
          const user = result.user;
          resolve(user);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  private socialRegisterApi(
    socialUserId: string,
    email: string,
    signupType: SocialTypeEnum
  ): Promise<ApiResponse<UserTokenData>> {
    const dataSocialRegister = {
      socialUserId,
      email,
      signupType,
    };

    const url = this.baseUrl + 'User/socialRegister';
    return lastValueFrom(
      this.httpClient.post<ApiResponse<UserTokenData>>(url, dataSocialRegister)
    );
  }

  private socialLogin(
    email: string,
    socialUserId: string,
    name:string,
    lastname:string
  ): Promise<UserTokenData> {
    const dataSocialLogin = {
      email,
      socialUserId,
      name,
      lastname
    };

    const url = this.baseUrl + 'User/socialLogin';
    return lastValueFrom(
      this.httpClient.post<UserTokenData>(url, dataSocialLogin)
    );
  }
  deleteUserbyPhone(data: any) {
    const url = this.baseUrl + 'User/deletebyPhone';
    return this.httpClient.post(url, data);
  }
}
