import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConectionService } from '../api-conection.service';
import { environment } from '../../../environments/environment';
import { ImageResult } from '../../models/image.model';
import { lastValueFrom } from 'rxjs';
import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  private baseUrl = environment.baseUrl;

  constructor(
    private httpClient: HttpClient,
    private readonly authenticationService: AuthenticationService
  ) { }

  createimage(urlImage: string = 'assets/avatarassetsimage.png'): Promise<ImageResult> {
    const url = this.baseUrl + 'Image/create';
    return lastValueFrom(this.httpClient.post<ImageResult>(url, {
      urlImage
    }));
  }

  public generateUrl(file: File): any {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    const url = environment.imageUrl + this.authenticationService.userId;
    return this.httpClient.post(url, formData);
  }
}
