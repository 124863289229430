import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { UtilService } from '../../services/util.service';
import { HttpClient } from '@angular/common/http';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageService } from '../../services/profile/image.service';
import { Image } from './../../models/image.model';
import { GlobalEventService } from '../../services/global-event.service';
import { ApiResponse } from 'src/app/models/api-response.model';
import { Gender } from 'src/app/models/gender.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { isNil } from 'lodash';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { LocalStorageKeyEnum } from 'src/app/models/enums/local-storage-key-enum';
import { CountryService } from 'src/app/services/location/country.service';

declare let google;

@Component({
  selector: 'app-profile-model',
  templateUrl: './profile-model.page.html',
  styleUrls: ['./profile-model.page.scss'],
})
export class ProfileModelPage implements OnInit {
  userProfile: any;
  userId: string;

  genders: Gender[];
  countries: any[];
  infoperfiltoview: any;
  imageProfile: any = null;
  languages: any;
  perfil: any;
  public registerUserForm: UntypedFormGroup;
  formErrors = {
    name: '',
    lastName: '',
    phone: '',
    gender: '',
    land: '',
    address: '',
    birthDate: '',
    lenguages1: '',
    description: '',
    postalCode: '',
    city: '',
    state: '',
    profileurl:'',
  };
  public laguagesProfile: any = [];
  public codes: any = [];
  public codeDial: any;
  public ciudades: any = [];
  public directions: any = [];

  constructor(
    private util: UtilService,
    public api: ApiService,
    public modalController: ModalController,
    private router: Router,
    public httpClient: HttpClient,
    private builder: UntypedFormBuilder,
    private imageService: ImageService,
    private alertController: AlertController,
    public event: GlobalEventService,
    private readonly authenticationService: AuthenticationService,
    private readonly profileService: ProfileService,
    private readonly countryService: CountryService
  ) {
    this.userId = localStorage.getItem('uid');
    this.registerUserForm = this.builder.group({
      Id: [this.userId, [Validators.required]],
      Name: [null, [Validators.required, Validators.minLength(2)]],
      LastName: [null, [Validators.required, Validators.minLength(2)]],
      FullName: [null],
      Phone: [null, [Validators.required]],
      Latitude: [null],
      Longitude: [null],
      City: [null, [Validators.required]],
      State: [null, [Validators.required]],
      PostalCode: [null],
      Description: [null],
      Lenguages1: [null],
      Lenguages: [null],
      UrlImage: [null],
      StrGender: [null, [Validators.required]],
      CodeDial: ['+52'],
      Land: [null],
      ProfileUrl: [null]
    });
  }

  async ngOnInit() {
    this.userProfile = await this.profileService.getUserProfile();
    const socialUserInformation = localStorage.getItem(
      LocalStorageKeyEnum.UserSocialInformation
    );
    console.log('socialUserInformation', socialUserInformation);
    console.log('userProfile', this.userProfile);

    if (!this.userProfile) {
      const user = JSON.parse(socialUserInformation);
      this.registerUserForm.patchValue({...user});
      this.imageProfile =  this.userProfile.UrlImage ?? user.imagen;
    } else {
      this.imageProfile =  this.userProfile.UrlImage;
      this.funcionverificarperfil(this.userProfile);
    };

    this.getLanguages();
    this.getCodes();
    this.getGenders();
    this.getCountries();
  }

  ionViewDidEnter() {
    this.util.closeLoading();
  }

  async getGenders() {
    await this.api.retrieveGenders().then((apiResponse: ApiResponse<Gender[]>) => {
      this.genders = apiResponse.result;
      if (this.userProfile.StrGender) {
        this.registerUserForm.patchValue({StrGender: this.userProfile.StrGender});
      }
    });
  }

  async getCountries() {
    await this.countryService.retrieveCountries().then((res: any) => {
      this.countries = res;
      if (this.userProfile.Land) {
        this.registerUserForm.patchValue({Land: this.userProfile.Land});
      }
    });
  }

  getCodes() {
    this.httpClient.get('assets/codes.json').subscribe((res: any) => {
      this.codes = res;
      if (this.infoperfiltoview.Phone) {
        for (const code of this.codes) {
          if (this.infoperfiltoview.Phone.startsWith(code.dialCode)) {
            this.registerUserForm.patchValue({CodeDial: code.dialCode});
            this.registerUserForm.patchValue({Phone: this.infoperfiltoview.Phone.slice(code.dialCode.length)});
            break;
          }
        }
      }
    });
  }

  getLanguages() {
    this.httpClient
      .get('assets/about-me/languages.json')
      .subscribe((res: any) => {
        this.languages = res;
        if (this.infoperfiltoview.lenguages) {
          const lenguages1 = this.infoperfiltoview.lenguages.split(',');
          this.laguagesProfile.push(lenguages1);
          this.infoperfiltoview.lenguages1 = this.laguagesProfile;
        }
      });
  }

  obtenerDireccion(lat, lng) {
    const latlng = new google.maps.LatLng(lat, lng);
    const geocoder = new google.maps.Geocoder();
    const that = this;
    geocoder.geocode({ latLng: latlng }, (results, status) => {
      if (status !== google.maps.GeocoderStatus.OK) {
        //alert(status);
      }
      if (status === google.maps.GeocoderStatus.OK) {
        console.log(results);
        that.registerUserForm.patchValue({
          Address1: results[0].formatted_address,
        });
      }
    });
  }

  funcionverificarperfil(profile) {
    this.infoperfiltoview = profile;
    if (
      this.infoperfiltoview.Latitude !== null &&
      this.infoperfiltoview.Longitude !== null
    ) {
      this.infoperfiltoview.Latitude =
        this.infoperfiltoview.Latitude.toString();
      this.infoperfiltoview.Longitude =
        this.infoperfiltoview.Longitude.toString();
    }
    this.registerUserForm.patchValue(this.infoperfiltoview);
  }

  updateSearchResults() {
    const geocoder = new google.maps.Geocoder();
    const that = this;
    geocoder.geocode(
      { Address: this.registerUserForm.value.postalCode },
      (results, status) => {
        that.ciudades = [];
        that.directions = [];
        if (results) {
          if (results.length === 1) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < results[0].address_components.length; ++i) {
              if (results[0].address_components[i].types[0] === 'locality') {
                that.registerUserForm.patchValue({
                  City: results[0].address_components[i].long_name,
                });
              }
              if (
                results[0].address_components[i].types[0] ===
                'administrative_area_level_1'
              ) {
                that.registerUserForm.patchValue({
                  State: results[0].address_components[i].long_name,
                });
              }

              if (results[0].address_components[i].types[0] === 'country') {
                that.registerUserForm.patchValue({
                  Land: results[0].address_components[i].long_name,
                });
              }
            }
            that.registerUserForm.patchValue({
              Latitude: results[0].geometry.location.lat(),
            });
            that.registerUserForm.patchValue({
              Longitude: results[0].geometry.location.lng(),
            });
          } else {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < results.length; ++i) {
              // eslint-disable-next-line @typescript-eslint/prefer-for-of
              for (let j = 0; j < results[i].address_components.length; ++j) {
                that.directions.push(results[i]);
                if (results[i].address_components[j].types[0] === 'locality') {
                  that.ciudades.push({
                    name: results[i].address_components[j].long_name,
                  });
                }
              }
            }
            that.registerUserForm.patchValue({ City: that.ciudades[0].name });
            this.changeCity();
          }
        }
      }
    );
  }

  changeCity() {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < this.directions.length; ++i) {
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let j = 0; j < this.directions[i].address_components.length; ++j) {
        if (
          this.registerUserForm.value.City ===
          this.directions[i].address_components[j].long_name
        ) {
          // eslint-disable-next-line @typescript-eslint/prefer-for-of
          for (
            let m = 0;
            m < this.directions[i].address_components.length;
            ++m
          ) {
            if (
              this.directions[i].address_components[m].types[0] ===
              'administrative_area_level_1'
            ) {
              this.registerUserForm.patchValue({
                State: this.directions[i].address_components[m].long_name,
              });
            }

            if (
              this.directions[i].address_components[m].types[0] === 'country'
            ) {
              this.registerUserForm.patchValue({
                Land: this.directions[i].address_components[m].long_name,
              });
            }
          }

          this.registerUserForm.patchValue({
            Latitude: this.directions[i].geometry.location.lat(),
          });
          this.registerUserForm.patchValue({
            Longitude: this.directions[i].geometry.location.lng(),
          });
        }
      }
    }
  }

  async takePicture(event: any) {
    const input = event.target.files[0] as File;
    const reader = new FileReader();

    reader.onload = (onLoadEvent: any) => {
      this.imageProfile = onLoadEvent.target.result;
      this.sendPhotos(input);
    };

    reader.readAsDataURL(event.target.files[0]);
  }

  sendPhotos(file) {
    this.imageService.generateUrl(file).subscribe(
      (x) => {
        const image = new Image();
        image.urlImage = x.value.urlImage;
        this.registerUserForm.patchValue({ UrlImage: image.urlImage });
      },
      (error) => {
        this.util.presentToast(error.error.value);
      }
    );
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  dismissyactualiza() {
    this.modalController.dismiss({
      dismissed: 'reconsultarperfil',
    });
  }

  gotoSesion() {
    this.router.navigate([`authentication`]);
  }

  crearperfil() {
    if (this.registerUserForm.valid) {
      this.registerUserForm.patchValue({
        Name: this.capitalize(this.registerUserForm.value.Name),
      });
      this.registerUserForm.patchValue({
        LastName: this.capitalize(this.registerUserForm.value.LastName),
      });

      this.registerUserForm.patchValue({
        Phone:
          this.registerUserForm.value.CodeDial +
          this.registerUserForm.value.Phone,
      });

      if (this.registerUserForm.value.Lenguages1 && this.registerUserForm.value.Lenguages1?.length > 0) {
        this.registerUserForm.patchValue({
          Lenguages: this.registerUserForm.value.Lenguages1.join(', ')
        });
      }
      console.log(this.registerUserForm.value);
      this.util.loading18segundos('Actualizando perfil...');
      this.api.updateProfile(this.registerUserForm.value).subscribe(
        async (res: any) => {
          localStorage.setItem(
            'codeTopmai',
            this.registerUserForm.value.CodeDial
          );
          this.util.closeLoading();
          this.util.presentToast('Perfil actualizado exitosamente');
          this.userProfile = await this.profileService.getUserProfile(true);
          
          this.dismissyactualiza();
        },
        (error) => {
          this.util.closeLoading();
          console.log('el error:', error?.error?.error);
          this.util.presentToast(error?.error?.error);
        }
      );
    } else {
      this.util.presentToast('Complete los campos del formulario');
      this.validateAllFormFields(this.registerUserForm);
    }
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      mode: 'ios',
      header: 'Eliminar cuenta de usuario',
      message:
        'Esta acción no se puede deshacer. Esto eliminará permanentemente el usuario: ' +
        this.userProfile.ProfileUrl +
        ', sus chats, compras, ventas, contactos y toda la información relacionada con Topmai. Por favor, escriba su ID de usuario: ' +
        this.userProfile.ProfileUrl +
        ' para confirmar.',
      buttons: [
        {
          text: 'Entiendo las consecuencias, eliminar mi cuenta en Topmai',
          handler: (data) => {
            console.log(data);
            if (data.name === '') {
              this.util.presentToast('Ingrese su ID de usuario para confirmar');
            } else if (data.name === this.userProfile.ProfileUrl) {
              this.deteleUser();
            } else {
              this.util.presentToast(
                'el ID ingresado no coincide con tu usuario'
              );
            }
          },
        },
      ],
      inputs: [
        {
          name: 'name',
          type: 'text',
          placeholder: '',
        },
      ],
    });

    await alert.present();
  }

  deteleUser() {
    const data = {
      id: this.userProfile.Id,
    };
    this.util.loading18segundos('Eliminando usuario');
    this.api.deleteProfile(data).subscribe(
      () => {
        this.api.deleteUser(data).subscribe(
          () => {
            this.logout();
          },
          (error) => {
            console.log('el error:', error);
            this.util.closeLoading();
            this.util.presentToast(error?.error?.error);
          }
        );
      },
      (error) => {
        console.log('el error:', error);
        this.util.closeLoading();
        this.util.presentToast(error?.error?.error);
      }
    );
  }

  logout() {
    const data = {
      id: localStorage.getItem('uid'),
    };
    this.api.disconnectOneSignal(data).subscribe(
      (res: any) => {
        localStorage.clear();
        localStorage.setItem('intro-seen', 'yes');
        this.event.publishSomeData({
          data: 'logout',
        });
        this.authenticationService.logoutFacebook();
        this.authenticationService.googleSignOut();
        this.util.closeLoading();
        this.util.presentToast('Usuario eliminado con éxito');
        this.dismissyactualiza();
      },
      (error) => {
        localStorage.clear();
        localStorage.setItem('intro-seen', 'yes');
        this.util.closeLoading();
        this.event.publishSomeData({
          data: 'logout',
        });
        this.logoutFacebook();
        // this.logoutGoogle();
        this.util.presentToast('Usuario eliminado con éxito');
        this.dismissyactualiza();
      }
    );
  }

  async logoutFacebook() {
    // await FacebookLogin.logout();
  }

  capitalize(text: string) {
    return text.replace(/\b\w/g, (message: string) => message.toUpperCase());
  }

  onValueChanged(data?: any) {
    if (!this.registerUserForm) {
      return;
    }
    const form = this.registerUserForm;

    // eslint-disable-next-line guard-for-in
    for (const field in this.formErrors) {
      const control = form.get(field);
      this.formErrors[field] = '';
      if (control && control.dirty && !control.valid) {
        // eslint-disable-next-line guard-for-in
        for (const key in control.errors) {
          this.formErrors[field] += true;
          console.log(key);
        }
      }
    }
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsDirty({ onlySelf: true });
        this.onValueChanged();
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
