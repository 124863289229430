<ion-header mode="ios">
  <ion-toolbar>
    <ion-buttons slot="start" (click)="dismiss()">
      <ion-icon style="font-size: 30px" name="chevron-back-outline"></ion-icon>
    </ion-buttons>
    <ion-title>Configuración</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <section>
    <div class="content-img">
      <label for="input">
        <img
          style="border-radius: 50em; display: block; object-fit: cover"
          [src]="imageProfile === null || imageProfile === '' ? 'assets/avatarassetsimage.png' : imageProfile"
        />
        <ion-icon name="camera"></ion-icon>
      </label>
      <input
        (change)="takePicture($event)"
        accept="image/*"
        class="input"
        type="file"
        id="input"
      />
    </div>

    <form [formGroup]="registerUserForm">
      <ion-list lines="full" class="ion-no-margin">
        <h2 class="title-info1" style="margin-top: 0px">
          <ion-icon
            style="font-size: 20px; margin-right: 10px"
            name="id-card-outline"
          ></ion-icon>
          Información Básica
        </h2>
        <ion-item lines="none">
          <ion-input
            label="Nombre:"
            formControlName="Name"
            type="text"
            style="text-transform: capitalize"
            placeholder="Ingrese Nombre"
            [ngClass]="{'danger': formErrors.name}"
          ></ion-input>
        </ion-item>
        <ion-item lines="none">
          <ion-input
            label="Apellido:"
            formControlName="LastName"
            style="text-transform: capitalize"
            type="text"
            placeholder="Ingrese Apellido"
            [ngClass]="{'danger': formErrors.lastName}"
          ></ion-input>
        </ion-item>
        <ion-item lines="none">
          <ion-input
            label="Id:"
            formControlName="ProfileUrl"
            style="text-transform: capitalize"
            type="text"
            placeholder="Ingrese Id"
            [ngClass]="{'danger': formErrors.profileurl}"
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-select
            label="Genero:"
            formControlName="StrGender"
            interface="popover"
            mode="ios"
            placeholder="Seleccione Género"
            style="max-width: 47%; padding-left: 0px; width: 100%"
            [ngClass]="{'danger': formErrors.gender}"
          >
            <ion-select-option *ngFor="let gender of genders" 
                               [value]="gender.Name"
              >{{gender.Name}}</ion-select-option
            >
          </ion-select>
        </ion-item>
        <ion-item lines="none">
          <ion-select
            label="Celular:"
            formControlName="CodeDial"
            mode="ios"
            interface="popover"
            [interfaceOptions]="{ cssClass: 'popover-wide' }"
            style="z-index: 999; font-weight: 500; max-width: 41%;
            margin-right: 5px;"
          >
            <ion-select-option
              *ngFor="let item of codes"
              value="{{item.dialCode}}"
              >{{item.flag}} {{item.dialCode}}</ion-select-option
            >
          </ion-select>
          <ion-input
            formControlName="Phone"
            type="tel"
            placeholder="Ingrese Celular"
            onkeyup="this.value = this.value.replace(/[+]/, '')"
          >
          </ion-input>
        </ion-item>
        <!--ion-item lines="none">
          <ion-label
            [ngClass]="{'danger': formErrors.birthDate}"
            style="max-width: 125px; font-weight: 500"
            >Fecha Nacimiento:
          </ion-label>
          <ion-datetime-button datetime="datetime"></ion-datetime-button>

          <ion-modal [keepContentsMounted]="true">
            <ng-template>
              <ion-datetime
                id="datetime"
                [showDefaultButtons]="true"
                [preferWheel]="true"
                doneText="Ok"
                cancelText="Cancelar"
                presentation="date"
                displayFormat="DD MMM, YYYY"
                formControlName="birthDate"
                min="1910-01-01"
                max="2004-01-01"
              ></ion-datetime>
            </ng-template>
          </ion-modal>
        </ion-item-->

        <h2 class="title-info">
          <ion-icon
            style="font-size: 20px; margin-right: 10px"
            name="location-outline"
          ></ion-icon>
          Dirección
        </h2>
        <ion-item lines="none">
          <ion-input
            label="Código postal:"
            type="text"
            formControlName="PostalCode"
            placeholder="Ingrese código postal"
            [ngClass]="{'danger': formErrors.postalCode}"
            (ionChange)="updateSearchResults()"
          ></ion-input>
        </ion-item>
        <ion-item lines="none" *ngIf="ciudades.length < 2">
          <ion-input
            label="Ciudad:"
            type="text"
            formControlName="City"
            placeholder="Ingrese Ciudad"
            [ngClass]="{'danger': formErrors.city}"
          ></ion-input>
        </ion-item>
        <ion-item *ngIf="ciudades.length > 1">
          <ion-label
            style="max-width: 23%; font-weight: 500"
            [ngClass]="{'danger': formErrors.city}"
            >Ciudad:</ion-label
          >
          <ion-select
            style="max-width: 77%; padding-left: 0px; width: 100%"
            interface="popover"
            okText="Ok"
            cancelText="Cancelar"
            formControlName="City"
            (ionChange)="changeCity()"
          >
            <ion-select-option
              *ngFor="let item of ciudades"
              value="{{item.name}}"
              >{{item.name}}</ion-select-option
            >
          </ion-select>
        </ion-item>
        <ion-item lines="none">
          <ion-input
            label="Estado:"
            type="text"
            formControlName="State"
            placeholder="Ingrese Estado"
            [ngClass]="{'danger': formErrors.state}"
          ></ion-input>
        </ion-item>
        <ion-item lines="none">
          <ion-select
            label="País:"
            formControlName="Land"
            interface="popover"
            mode="ios"
            placeholder="Seleccione País"
            style="max-width: 47%; padding-left: 0px; width: 100%"
            [ngClass]="{'danger': formErrors.land}"
          >
            <ion-select-option *ngFor="let country of countries" 
                              [value]="country.Name"
              >{{country.Name}}</ion-select-option
            >
          </ion-select>
        </ion-item>
        <h2 class="title-info">
          <ion-icon
            style="font-size: 20px; margin-right: 10px"
            name="language-outline"
          ></ion-icon>
          Idiomas
        </h2>
        <ion-item>
          <ion-select
            label="Lenguajes:"
            style="max-width: 77%; padding-left: 0px; width: 100%"
            [interfaceOptions]="{cssClass: 'custom-options-alert'}"
            multiple="true"
            okText="Ok"
            cancelText="Cancelar"
            formControlName="Lenguages1"
            [ngClass]="{'danger': formErrors.lenguages1}"
          >
            <ion-select-option
              *ngFor="let cadaidioma of languages"
              value="{{cadaidioma.name}}"
              >{{cadaidioma.name}}</ion-select-option
            >
          </ion-select>
        </ion-item>

        <h2 class="title-info">
          <ion-icon
            style="font-size: 20px; margin-right: 10px"
            name="person-outline"
          ></ion-icon>
          Acerca de tí
        </h2>
        <ion-item lines="none">
          <ion-textarea
            label="Descripción:"
            formControlName="Description"
            type="text"
            rows="6"
            cols="20"
            placeholder="Ingrese una breve descripción sobre tí"
            [ngClass]="{'danger': formErrors.description}"
          ></ion-textarea>
        </ion-item>
      </ion-list>

      <ion-row class="row" class="ion-no-padding" no-padding>
        <ion-col size="1"> </ion-col>
        <ion-col size="10">
          <ion-text style="font-size: small; font-weight: 500"
            >Complete los datos y presione para continuar:</ion-text
          >
          <ion-button
            (click)="crearperfil()"
            style="
              --background: #4cd080;
              margin-top: 10px;
              margin-bottom: 20px;
              border-radius: 20px;
            "
            expand="block"
          >
            <span *ngIf="!this.userProfile">Crear Perfil</span>
            <span *ngIf="this.userProfile">Actualizar Perfil</span>
          </ion-button>
        </ion-col>
        <ion-col size="1"> </ion-col>
      </ion-row>
    </form>
  </section>

  <div *ngIf="this.userProfile" style="padding: 0px 40px 40px">
    <a (click)="presentAlert()">Eliminar cuenta</a>
  </div>
</ion-content>
